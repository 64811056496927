.program_navbar_wrapper {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3 !important;
    .program_navbar {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        padding: 20px 35px;
        align-items: center;
        max-height: 88px;
        border-bottom: 1px solid var(--gs-learn-light-mode-grey-5, rgba(3, 10, 33, 0.05));
        background: var(--gs-learn-mockingbird, #030A21);

        .program_navbar_left_content {
            gap: 10px;
            align-items: center;
            .marketing_text_wrapper {
                border-radius: 20px;
                background: rgba(255, 255, 255, 0.10);
                padding: 12px;
                align-items: center;
                gap: 11px;
                img {
                    max-width: 75px;
                    max-height: 24px;
                }
            }
        }

        .program_navbar_right_content {
            justify-self: flex-end;
        }
    }
}

@media screen and (max-width: 800px) {
    .program_navbar_wrapper {
        all: unset;
        position: fixed !important;
        bottom: 65px !important;
        z-index: 52;
        left: 0;
        width: 100%;
        .program_navbar {
            flex-direction: column-reverse;
            max-height: unset !important;
            gap: 13px;
            .program_navbar_right_content {
                justify-self: unset;
                width: 100%;
            }
        .program_title_wrapper {
            display: none;
        }
    }
    }
}